import logo from './logo.svg';
import './App.css';
import CopyLink from './CopyLink';



const App = () => {
  const links = [
    { link: 'https://linkedin.com/in/AvikantSrivastava', linkName: 'LinkedIn' },
    { link: 'https://github.com/AvikantSrivastava', linkName: 'GitHub' },
    { link: 'https://twitter.com/Aesthetixell', linkName: 'twitter' },
    { link: 'https://blog.avikant.com/', linkName: 'Blog' },
    { link: 'https://avikant.com/', linkName: 'Portfolio' },
    { link: 'https://drive.google.com/file/d/1kffvEbrax66swxQ8aUmVdH5wIE1O01vH/view', linkName: 'resume' },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <h1>Avikant Srivastava</h1>
      {links.map(({ link, linkName }) => (
        <CopyLink key={link} link={link} linkName={linkName} />
      ))}
    </div>
  );
};

export default App;
