import React, { useState } from 'react';
import copy from 'clipboard-copy';

const CopyLink = ({ link, linkName }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await copy(link);
      setIsCopied(true);
    } catch (err) {
      console.error('Error copying to clipboard:', err);
    }

    // Reset the copied state after a short delay
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <div>
        <div className="col-6 col">
            <button  className="btn-bloc" onClick={handleCopyClick} disabled={isCopied}>
            {isCopied ? 'Copied!' : `${linkName}`}
            </button>
        </div>
  </div>
  );
};

export default CopyLink;
